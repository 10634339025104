import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import useArtist from '@/hooks/artist/useArtist';
import { MarketingAssistantUserInfoModel } from '@/models/MarketingAssistant';
import MarketingAssistantAPI from '@/network/MarketingAssistantAPI';

import useAccountContext from '../context/useAccountContext';

const useAccountArtistInfo = () => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MarketingAssistantUserInfoModel> | null>(
    () => {
      if (!accountId) return null;

      return `account-artist-info-${accountId}-${artist?.id}`;
    },
    async () => {
      if (!accountId) return null;

      return await MarketingAssistantAPI.getMarketingAssistantUserInfo({ accountId: accountId, artistId: artist?.id });
    }
  );

  return {
    accountArtistInfo: data?.data,
    accountArtistInfoIsLoading: isLoading,
    accountArtistInfoError: error,
    refetchAccountArtistInfo: mutate,
  };
};

export default useAccountArtistInfo;
