import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MarketingAssistantContentAPIModel } from '@/models/MarketingAssistant';
import MarketingAssistantAPI from '@/network/MarketingAssistantAPI';

import useAccountContext from '../context/useAccountContext';

const useMarketingAssistantContent = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MarketingAssistantContentAPIModel> | null>(
    () => {
      if (!accountId) return null;

      return `marketing-assistant-content-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await MarketingAssistantAPI.getMarketingAssistantContent({ accountId: accountId, pageSize: 20 });
    }
  );

  return {
    marketingAssistantContent: data?.data,
    marketingAssistantContentIsLoading: isLoading,
    marketingAssistantContentError: error,
    refetchMarketingAssistantContent: mutate,
  };
};

export default useMarketingAssistantContent;
